import React, { useEffect, useState } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';
import Ut from '../general/ut';

import AssemblyRegion                     from '../models/assemblyRegion';

//********************************************************************
// PageRegionSearch
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageRegionSearch( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup      , setPopup     ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy       , setBusy      ] = useState( false );
    const [ mdlRegion  , setMdlRegion ] = useState( new AssemblyRegion() );
    const [ lstRegion  , setLstRegion ] = useState<AssemblyRegion[]>( [] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtCon ss='12|12|12|10|8' md='rw' lt='z,a' marB={15}>
            <FtButton label="New Region" onTap={onClickNewRegion} {...Th.button} />
        </FtCon>
                
        <FtFrame text='Search Parameters' {...Th.frame} ss='12|12|12|10|8'>
            <FtForm ss='12' {...Th.form}
                models={{ m: mdlRegion }}
                onSubmit={onSearch}
            >
                <FtFmTextField     name='m.region_name'      label='Region Name'   {...Th.field} />
                <FtFmTextField     name='m.region_no'        label='Region No'     {...Th.field} />
                <FtFmDropdownField name='m.province_id'      label='Province'      {...Th.field} data={Ut.lkpProvince} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Search' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

        <FtFrame text='Search Results' {...Th.frame} ss='12'>
            <FtDataGrid {...Th.grid} pgsize={50}
                data={lstRegion}
                cols={[
                    { fld: ''                                   , hd: 'Edit'                               , type: 'act'  , flex: 1,  fmt: 'mc-pencil', clr: '#808080', act: onTapAssemblyRegionEdit },
                    { fld: 'region_name'                        , hd: 'Region Name'                        , type: 'txt'  , flex: 3 },
                    { fld: 'province_id'                        , hd: 'Province'                           , type: 'lkp'  , flex: 3,  fmt: '', lkp: Ut.lkpProvince! },
                    { fld: 'region_no'                          , hd: 'Region No'                          , type: 'txt'  , flex: 1 },
                    { fld: 'status'                             , hd: 'Status'                             , type: 'txt'  , flex: 1 },
                    { fld: ''                                   , hd: 'Delete'                             , type: 'act'  , flex: 1,  fmt: 'fa-trash', clr: '#808080', act: onTapAssemblyRegionDelete },
                ]}
            />
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers
    
    function onLoad(): void
    {
        setBusy( true );
        Ut.loadLookups( {
            success: ( result, data ) => setBusy( false ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });
    }

    function onSearch(): void
    {
        setBusy( true );
        setLstRegion( [] );
        FtRapi.callList( 'assemblyRegionSearch', {
            urlParms: {
                prm_region_name : mdlRegion.region_name,
                prm_region_no   : mdlRegion.region_no,
                prm_province_id : mdlRegion.province_id,
            },
            success: ( result, data ) => setLstRegion( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onTapAssemblyRegionEdit( { dataId }: FtDgAction ): void
    {
        nav.push( 'RegionEdit', { regionId: dataId } );
    }

    function onTapAssemblyRegionDelete( { dataId }: FtDgAction ): void
    {
        ftPopupConfirm( setPopup, 'Confirmation', "Are you sure you want to delete this record?", {
            btnA: 'Yes', onA: () => {
                setLstRegion( [] );
                setBusy( true );
                FtRapi.callDelete( 'assemblyRegionDelete', dataId, {
                    success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { onClose: onSearch } ),
                    error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                    complete: () => setBusy( false ),
                });
            },
            btnB: 'No', onB: () => {}
        });
    }

    function onClickNewRegion(): void
    {
        nav.push( 'RegionEdit', { regionId: 0 } );
    }

}
