import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtButton from '../first/ftButton';
import { ftBusy } from '../first/ftBusy';
import Th from '../general/th';
import Ut from '../general/ut';


//********************************************************************
// PageMenuAssembly
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageMenuAssembly( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState<boolean>( false );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy ) return ftBusy( { popup: popup } );

    const stl: any = { txtClr: '#ffffff' };

    return (<FtCanvas {...Th.page} lt='a,a'>
        {popup}

        <FtFrame text='Assemblies' {...Th.menuFrame}>
            <FtCon {...Th.menuBlock}>
                <FtButton label="Assemblies List"                           {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("AssemblySearch")} />
                <FtButton label="Report: Assemblies Master"                 {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.SYSTEMADMIN )} onTap={() => nav.push("ReportAssemblyMaster")} />
                <FtButton label="Report: Assembly Secretary Labels"         {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportLabelAssemblySecretary")} />
                <FtButton label="Report: Assembly Added in Last 6 Months"   {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportAssemblyAddedLast6Months")} />
                <FtButton label="Report: Assemblies Without Pastors"        {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportAssemblyNoPastors")} />
                <FtButton label="Report: Assembly Number List"              {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportAssemblyNumberList")} />
                <FtButton label="Report: Assembly Number List Sum"          {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportAssemblyNumberListSum")} />
                <FtButton label="Report: Assembly Pastors List"             {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportAssemblyPastorsList")} />
                <FtButton label="Report: Labels Without Mail Addresses"     {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportLabelNoMailAddresses")} />
                <FtButton label="Report: Regional List"                     {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportRegionList")} />
                <FtButton label="Report: Regional Office Bearers"           {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportRegionOfficeBearers")} />
                <FtSpacer />
                <FtButton label="Back to Main Menu"                 {...Th.menuBtn}   {...stl}  onTap={nav.pop} />                
            </FtCon>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers
    
}
