import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtButton from '../first/ftButton';
import { ftBusy } from '../first/ftBusy';
import Th from '../general/th';
import Ut from '../general/ut';


//********************************************************************
// PageMenuPastor
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageMenuPastor( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState<boolean>( false );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy ) return ftBusy( { popup: popup } );

    const stl: any = { txtClr: '#ffffff' };

    return (<FtCanvas {...Th.page} lt='a,a'>
        {popup}

        <FtFrame text='Pastors' {...Th.menuFrame}>
            <FtCon {...Th.menuBlock}>
                <FtButton label="Pastors List"                              {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("PastorSearch")} />
                <FtButton label="Report: Pastors Master"                    {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.SYSTEMADMIN )} onTap={() => nav.push("ReportPastorMaster")} />
                <FtButton label="Report: Marriage Officer Pastors"          {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorMarriageOfficers")} />
                <FtButton label="Report: Pastors by Gender"                 {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorGender")} />
                <FtButton label="Report: Pastor Re-Instated"                {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorReinstated")} />
                <FtButton label="Report: Pastors 40 Year Ministry"          {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastor40Year")} />
                <FtButton label="Report: Pastors Renewed"                   {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorRenewed")} />
                <FtButton label="Report: Pastors Seconded All"              {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorSecondedAll")} />
                <FtButton label="Report: Pastors Seconded to Forces"        {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorSecondedForces")} />
                <FtButton label="Report: Pastors Status"                    {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastoralStatus")} />
                <FtButton label="Report: Pastors Status Lapsed"             {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastoralStatusLapsed")} />
                <FtButton label="Report: Pastors Status Lapsed Warning"     {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastoralStatusLapsedWarning")} />
                <FtButton label="Report: Duplicates Pastors"                {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorDuplicates")} />
                <FtButton label="Report: Pastors Movement Accepted"         {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorMovementAccepted")} />
                <FtButton label="Report: Pastors Movement Called"           {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorMovementCalled")} />
                <FtButton label="Report: Pastors Retire"                    {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.SYSTEMADMIN )} onTap={() => nav.push("ReportPastorRetire")} />
                <FtSpacer />
                <FtButton label="Back to Main Menu"                 {...Th.menuBtn}   {...stl}  onTap={nav.pop} />                
            </FtCon>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

}
