import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtText from '../first/ftText';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmCheckboxField from '../first/ftFmCheckboxField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtFmSearchField from '../first/ftFmSearchField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup                          from '../models/genLookup';

//********************************************************************
// PageReportAssemblyMaster
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageReportAssemblyMaster( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState( false );
    const [ lkpProvince            , setLkpProvince            ] = useState<GenLookup[]>( [] );
    const [ lkpRegion              , setLkpRegion              ] = useState<GenLookup[]>( [] );
    const [ mdlParms               , setMdlParms               ] = useState( {
        province_id           : null,
        region_id             : null,
        group_provinces       : true,
        group_regions         : true,
        include_non_rsa       : true,
        include_depts         : false,
    } );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( lkpRegion, lkpProvince ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Report Parameters' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlParms }}
                onSubmit={onSubmitReport}
            >
                <FtText ss='1:5' txtDescr='20,WCO#273891' text='Provinces:' />
                <FtText ss='1:5' txtDescr='20,WCO#273891' text='Regions:' />

                <FtFmDropdownField name='m.province_id'       label='Filter by Province'            {...Th.field} data={lkpProvince} />
                <FtFmSearchField   name='m.region_id'         label='Filter by Region'              {...Th.field} data={lkpRegion} />                                

                <FtFmCheckboxField name='m.group_provinces'   label='Group by Provinces'            {...Th.field} />
                <FtFmCheckboxField name='m.group_regions'     label='Group by Regions'              {...Th.field} />

                <FtFmCheckboxField name='m.include_non_rsa'   label='Include Outside South-Africa'  {...Th.field} />
                <FtFmCheckboxField name='m.include_depts'     label='Include Depts/Spec-Mins'       {...Th.field} />

                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Generate Report' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        setBusy( true );
        FtRapi.callList( 'assemblyRegionLookup', {
            success: ( result, data ) => {
                setLkpRegion( data[0] );
                setLkpProvince( data[1] );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { btn: "Close", onClose: () => nav.pop() } ),  
            complete: () => setBusy( false ),
        });
    }

    function onSubmitReport(): void
    {
        Ut.openReport( 'reportAssemblyMaster', mdlParms );
    }

}
