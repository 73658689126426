import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmSearchField from '../first/ftFmSearchField';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup  from '../models/genLookup';
import Assembly   from '../models/assembly';

//********************************************************************
// PageAssemblySearch
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageAssemblySearch( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState( false );
    const [ mdlAssembly            , setMdlAssembly            ] = useState( new Assembly() );
    const [ lkpRegion              , setLkpRegion              ] = useState<GenLookup[]>( [] );
    const [ lstAssembly            , setLstAssembly            ] = useState<Assembly[]>( [] );
    const [ info                   , setInfo                   ] = useState<any>( { result_count: 0 } );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy || Ft.eAny( lkpRegion ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtCon ss='12|12|12|9' md='rw' lt='z,a' marB={15}>
            <FtButton label="New Assembly" onTap={onClickNewAssembly} {...Th.button} />
        </FtCon>
                
        <FtFrame text='Search Parameters' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlAssembly }}
                onSubmit={onSearch}
            >
                <FtFmTextField     name='m.assembly_no'                        label='Assembly No'                        {...Th.field} />
                <FtFmTextField     name='m.assembly_name'                      label='Assembly Name'                      {...Th.field} />
                <FtFmSearchField   name='m.region_id'                          label='Region'                             {...Th.field} data={lkpRegion} />
                <FtFmTextField     name='m.physical_adr_1'                     label='Address'                            {...Th.field} />
                <FtFmTextField     name='m.physical_adr_city'                  label='City'                               {...Th.field} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Search' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

        <FtFrame text={`Search Results [ ${info.result_count} ]`} {...Th.frame} ss='12'>
            <FtDataGrid {...Th.grid} pgsize={50}
                data={lstAssembly}
                cols={[
                    { fld: ''                    , hd: 'Edit'                , type: 'act'  , flex: 1,  fmt: 'mc-pencil'     , clr: '#808080', act: onTapAssemblyEdit },
                    { fld: ''                    , hd: 'Properties'          , type: 'act'  , flex: 2,  fmt: 'mc-greenhouse' , clr: '#808080', act: onTapAssemblyProps },
                    { fld: ''                    , hd: 'Folders'             , type: 'act'  , flex: 1,  fmt: 'mc-folder'     , clr: '#808080', act: onTapAssemblyFolder },
                    { fld: 'assembly_no'         , hd: 'Assembly No'         , type: 'txt'  , flex: 3 },
                    { fld: 'assembly_name'       , hd: 'Assembly Name'       , type: 'txt'  , flex: 3 },
                    { fld: 'region_id'           , hd: 'Region'              , type: 'lkp'  , flex: 3, lkp: lkpRegion },
                    { fld: 'physical_adr_1'      , hd: 'Phy.Address'         , type: 'txt'  , flex: 3 },
                    { fld: 'physical_adr_city'   , hd: 'City'                , type: 'txt'  , flex: 3 },
                    { fld: 'phone_no'            , hd: 'Phone No'            , type: 'txt'  , flex: 3 },
                    { fld: 'email'               , hd: 'Email'               , type: 'txt'  , flex: 3 },
                ]}
            />
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers
    
    function onLoad(): void 
    {
        setBusy( true );
        FtRapi.callList( 'assemblyRegionLookup', {
            success: ( result, data ) => setLkpRegion( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { btn: "Close", onClose: () => nav.pop() } ),  
            complete: () => setBusy( false ),
        });
    }

    function onSearch(): void
    {
        setLstAssembly( [] );
        setBusy( true );
        FtRapi.callList( 'assemblySearch', {
            urlParms: {
                prm_assembly_no       : mdlAssembly.assembly_no,
                prm_assembly_name     : mdlAssembly.assembly_name,
                prm_region_id         : mdlAssembly.region_id,
                prm_physical_adr_1    : mdlAssembly.physical_adr_1,
                prm_physical_adr_city : mdlAssembly.physical_adr_city,
            },
            success: ( result, data ) => {
                info.result_count = data[0]?.length ?? 0;
                setLstAssembly( data[0] );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onTapAssemblyEdit( { dataId }: FtDgAction ): void
    {
        nav.push( 'AssemblyEdit', { assemblyId: dataId } );
    }
    
    function onTapAssemblyProps( { dataId }: FtDgAction ): void
    {
        nav.push( 'PropertySearch', { assemblyId: dataId } );
    }

    function onTapAssemblyFolder( { dataItem }: FtDgAction ): void
    {
        Ft.openUrl( `${Ut.assemblyFolderLink}/Assemblies${dataItem.assembly_no}` );
    }    
    
    function onClickNewAssembly(): void 
    {
        setLstAssembly( [] );   
        nav.push( 'AssemblyEdit', { assemblyId: 0 } );
    }
}
